<template>
	<div>
		<div
			class="Main width_1200 margin_auto flex justify_content_sb"
			style="padding-bottom: 20px"
		>
			<div class="MainLeft">
				<div class="zt">{{ noticeDetails.update_date }}</div>
				<div
					class="flex justify_content_sb align_items_c"
					style="margin-top: 11px"
				>
					<div class="kxwzDate">
						发布时间：{{ noticeDetails.update_date }}
					</div>
				</div>
				<div class="kxList">
					<div class="kxItem">
						<div class="kxItem_1">通知名称：{{ noticeDetails.title }}</div>
						<div class="kxItem_2 ql-snow">
							<p class="ql-editor" v-html="noticeDetails.desc"></p>
						</div>
					</div>
				</div>
			</div>
			<div class="MainRight">
				<div class="MainRight_1 flex align_items_c">
					<img
						src="https://res.metaera.hk/resources/assets/images/kxwz/254.png"
						alt=""
					/>
					<div>投资有风险，入市须谨慎。本资讯不作为投资建议。</div>
				</div>
				<div class="MainRight_2 margin_top_20">
					<event type="recommend" title="主编推荐"></event>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getNoticeDetail } from "@/api/home";
import start from "@/components/scare.vue";
import { myMixin } from "@/utils/mixin.js";
export default {
	mixins: [myMixin],
	name: "",
	data() {
		return {
			noticeDetails: {},
			text: "",
		};
	},
	components: {
		start,
	},
	created() {
		this.getNoticeDetail(this.$route.params.id);
	},
	methods: {
		getNoticeDetail(id) {
			getNoticeDetail({ id: id })
				.then((res) => {
					if (res.code == 200) {
						this.noticeDetails = res.data;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.Main {
	padding-top: 20px;
}

.MainLeft {
	width: 600px;
}

.zt {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #29293b;
	margin-top: 49px;
}

.kxwzDate {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #9a9aa7;
}

.kxpj img {
	width: 15.7px;
	margin-left: 12px;
}

.kxItem_1 {
	font-size: 22px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #c4182d;
	margin-top: 33px;
}

.kxItem_2 {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #333333;
	line-height: 36px;
	margin-top: 18px;
}

.kxItem div:nth-child(3) span {
	display: flex;
	align-items: center;
}

.kxItem_3 {
	margin-top: 20px;
}

.kxItem_3 .btnitem {
	margin-left: 42px;
}

.kxItem_3 .btnitem:nth-child(1) {
	margin-left: 0;
}

.kxItem_3 a {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
}

.kxItem_4 {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	margin-top: 45px;
}

.kxItem_5 {
	width: 100%;
	height: 54px;
	background: #ffffff;
	border-radius: 12px 12px 12px 12px;
	opacity: 1;
	border: 1px solid #f5f5f5;
	margin-top: 20px;
}

.kxItem_5 .el-input {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
}

.kxItem_6 {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
	margin-top: 33px;
	width: 88px;
	height: 33px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 17px 17px 17px 17px;
	opacity: 1;
}

.kxItem_7 {
	margin-top: 6px;
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.kxItem_8 {
	margin-top: 18px;
}

.kxItem_8 img {
	width: 150px;
	height: 150px;
}

.kxItem_8 div {
	margin-top: 5px;
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #a6a6ad;
}

.MainRight {
	width: 360px;
}

.MainRight_1 {
	width: 100%;
	height: 58px;
	background: rgba(255, 255, 255, 0);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #f7f7f7;
}

.MainRight_1 img {
	width: 28px;
	height: 33px;
	margin-left: 17px;
}

.MainRight_1 div {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000;
	margin-left: 12px;
}
</style>
